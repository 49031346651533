import React from "react";
import { Layout } from "../components";
import styled from "styled-components";
import loadable from "@loadable/component";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Animation = loadable(() => import("../components/animation"));

export default ({ location, data }) => {
    const {
        space: {
            childImageSharp: { fluid },
        },
    } = data;

    return (
        <StyledBackgroundImage fluid={fluid}>
            <Layout notFound={true}>
                <Wrapper>
                    <h1>404</h1>
                    <h3>Path {location.pathname} could not be found.</h3>
                    <Animation />
                </Wrapper>
            </Layout>
        </StyledBackgroundImage>
    );
};

export const query = graphql`
    query {
        space: file(relativePath: { eq: "space.jpeg" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
    min-height: 100vh;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 6rem;
        color: white;
    }
    h3 {
        font-size: 2.5rem;
        color: white;
    }
`;
